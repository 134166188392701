import React from 'react';
import { NavLink } from 'react-router-dom';

export const HeaderNav = () => {
  return (
    <div className="layout">
      <header className="header">
        <div className="logo">
          <img src="./images/logo.png" alt="Reproducir" className="play" />
        </div>
        <h1>Mi Menú semanal</h1>
      </header>
      <nav className="nav">
        <ul>
          <li>
            <NavLink to="/inicio" className="nav-link">Inicio</NavLink>
          </li>
          <li>
            <NavLink to="/recetas" className="nav-link">Recetas</NavLink>
          </li>
          <li>
            <NavLink to="/contacto" className="nav-link">Contacto</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}
