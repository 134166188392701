import React, { useState } from 'react';

export const Buscador = ({ listadoState, setListadoState }) => {
  const [busqueda, setBusqueda] = useState('');

  const buscarMenu = (e) => {
    const nuevaBusqueda = e.target.value;
    setBusqueda(nuevaBusqueda);

    let menus_encontrados = [];

    if (nuevaBusqueda === "") {
      // Si el campo de búsqueda está vacío, mostrar todos los elementos
      menus_encontrados = JSON.parse(localStorage.getItem('menus'));
    } else {
      // Realizar la búsqueda
      menus_encontrados = JSON.parse(localStorage.getItem('menus')).filter((menu) => {
        return menu.titulo.toLowerCase().includes(nuevaBusqueda.toLowerCase());
      });
    }

    setListadoState(menus_encontrados);
  };

  return (
    <div className="search">
      <h3 className="title">Buscador: </h3>
      <form>
        <input
          type="text"
          id="search_field"
          name="busqueda"
          autoComplete="off"
          value={busqueda}
          onChange={buscarMenu}
        />
      </form>
    </div>
  );
};
