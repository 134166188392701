import React from 'react'

export const Editar = ({menu, conseguirMenus, setEditar, setListadoState}) => {
    const titulo_componente = "Editar menu";

    const guardarEdicion = (e, id) => {
        e.preventDefault();

        //Conseguir el target del evento
        let target = e.target;

        //Buscar el indice del objeto del menu a actualizar
        const menus_almacenados = conseguirMenus();
        const indice = menus_almacenados.findIndex(menu => menu.id === id);

        //Crear un objeto con el id de ese indice, con titulo y descripcion del formulario
        let menus_actualizados = {
            id,
            titulo: target.titulo.value,
            descripcion: target.descripcion.value,
        }

        //Actualizar el elemento con ese indice
        menus_almacenados[indice] = menus_actualizados;

        //Guardar el nuevo array de objetos en el localStorage
        localStorage.setItem("menus", JSON.stringify(menus_almacenados));

        //Actualizar estados
        setListadoState(menus_almacenados);
        setEditar(0)

    }

  return (
    // Agrega estas clases CSS al formulario y a los elementos dentro de la tarjeta
    <div className='edit-form'>
     <h3 className='title'>{titulo_componente}</h3>
    <form onSubmit={e => guardarEdicion(e, menu.id)} className='edit-card'>
    <input
      type="text"
      name="titulo"
      className="titulo-editado"
      defaultValue={menu.titulo}
    />

    <textarea
      name="descripcion"
      className="descripcion-original"
      defaultValue={menu.descripcion}
    />

    <input type="submit" className="editar-button" value="Actualizar" />
  </form>
</div>

  )
}
