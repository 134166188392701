export const GuardarEnStorage = (clave, elemento) => {
    // Obtener la lista de películas actual del localStorage
    let elementos = JSON.parse(localStorage.getItem(clave,));

    // Comprobar si hay un array
    if(Array.isArray(elementos)){
        elementos.push(elemento);
    }else{
        //Crear un array con el nuevo elemento
        elementos = [elemento];
    }

    // Guardar la lista actualizada en el localStorage
    localStorage.setItem(clave, JSON.stringify(elementos));

    // Devolver objeto guardado
    return elemento;
}