import React from 'react';
import { Routes, Route, HashRouter, Navigate } from "react-router-dom";
import Inicio from "../components/Inicio";
import { Recetas } from "../components/Recetas";
import { Contacto } from "../components/Contacto";
import { HeaderNav } from '../components/layout/HeaderNav';
import { Footer } from '../components/layout/Footer';

export const RouterPrincipal = () => {
  return (
    <HashRouter>
      <div className="app-container">
      {/* HEADER Y NAVEGACION */}
      <HeaderNav />

      {/* CONTENIDO CENTRAL */}
      <section className="content">
        <Routes>
          <Route path="/" element={<Navigate to="/inicio" />} />
          <Route path="/inicio" element={<Inicio />} />
          <Route path="/recetas" element={<Recetas />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="*" element={
            <div className='page'>
              <h1 className='heading'>Error 404</h1>
            </div>
          }/>
        </Routes>
      </section>

      {/* FOOTER */}
      <Footer />
      </div>
    </HashRouter>
  )
}
