import React, { useState } from 'react';
import { GuardarEnStorage } from '../helpers/GuardarEnStorage';

export const Añadir = ({ setListadoState }) => {
  const tituloComponente = "Añadir menu";

  const [menuState, setMenuState] = useState({
    titulo: '',
    descripcion: ''
  });

  const { titulo, descripcion, menuAgregado } = menuState;

  const conseguirDatosForm = (e) => {
    e.preventDefault();

    // Conseguir datos del formulario
    let target = e.target;
    let nuevoTitulo = target.titulo.value;
    let nuevaDescripcion = target.descripcion.value;

    // Crear objeto de la película a guardar
    let menu = {
      id: new Date().getTime(),
      titulo: nuevoTitulo,
      descripcion: nuevaDescripcion
    };

    // Actualizar el estado del listado principal
    setListadoState((elementos) => {
      if (Array.isArray(elementos)) {
        return [...elementos, menu];
      } else {
        return [menu];
      }
    });

    // Guardar en el almacenamiento local
    GuardarEnStorage("menus", menu);

    // Restablecer los campos y el mensaje
    setMenuState({
      titulo: '',
      descripcion: '',
      recetaAgregada: `Has añadido: ${nuevoTitulo}`
    });

    // Eliminar el mensaje de aviso después de 3 segundos
    setTimeout(() => {
    setMenuState(prevState => ({
      ...prevState,
      menuAgregado: ''
    }));
     }, 1500);


  };

  return (
    <div className="add">
      <h3 className="title">{tituloComponente}</h3>

      <strong>{menuAgregado}</strong>

      <form onSubmit={conseguirDatosForm}>
        <input
          type="text"
          id="titulo"
          name="titulo"
          placeholder="Día de la semana"
          value={titulo}
          onChange={(e) => setMenuState({ ...menuState, titulo: e.target.value })}
        />

        <textarea
          id="descripcion"
          name="descripcion"
          placeholder="Menú"
          value={descripcion}
          onChange={(e) => setMenuState({ ...menuState, descripcion: e.target.value })}
        />

        <input type="submit" id="save" value="Guardar" />
      </form>
    </div>
  );
};
