import React, { useState } from 'react';

export const Recetas = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseOver = (index) => {
    setHoveredIndex(index);
  };

  return (
    <div className='page'>
      <h1 className='heading'>Páginas de recetas</h1><br/>

      <h3>Aquí tenemos varios tipos de páginas para coger ideas para nuestro menú</h3>

      

      <div className="recetas-container">
        <div className="receta-card" onMouseOver={() => handleMouseOver(0)}>
          <img src="./images/saludable.png" alt="Imagen" />
          <div className="receta-info">
             <h2>Recetas Saludables</h2>
             <a href="https://www.lacocinasana.com/" target="_blank">Ir a la página</a>
          </div>
        </div>

        <div className="receta-card" onMouseOver={() => handleMouseOver(1)}>
          <img src="./images/vegana.png" alt="Imagen" />
          <div className="receta-info">
             <h2>Recetas Veganas</h2>
             <a href="https://danzadefogones.com/" target="_blank">Ir a la página</a>
          </div>
        </div>

        <div className="receta-card" onMouseOver={() => handleMouseOver(2)}>
          <img src="./images/singluten.png" alt="Imagen" />
          <div className="receta-info">
             <h2>Recetas Sin Gluten</h2>
             <a href="https://www.glotonessingluten.com/recetas-sin-gluten/" target="_blank">Ir a la página</a>
          </div>
        </div>

        <div className="receta-card" onMouseOver={() => handleMouseOver(3)}>
          <img src="./images/postres.png" alt="Imagen" />
          <div className="receta-info">
             <h2>Postres</h2>
             <a href="https://www.dulcesentimiento.com/" target="_blank">Ir a la página</a>
          </div>
        </div>
      </div>
    </div>
  );
}

