import React, { useEffect, useState, useCallback } from 'react';
import { Editar } from './Editar';

export const Listado = ({ listadoState, setListadoState }) => {
  const [editar, setEditar] = useState(0);

  const conseguirMenus = useCallback(() => {
    let menus = JSON.parse(localStorage.getItem("menus")) || [];
    setListadoState(menus);
    return menus;
  }, [setListadoState]);

  useEffect(() => {
    conseguirMenus();
  }, [conseguirMenus]);

  const borrarMenu = (id) => {
    let menus_almacenados = conseguirMenus();
    let nuevo_array_menus = menus_almacenados.filter(menu => menu.id !== parseInt(id));
    setListadoState(nuevo_array_menus);
    localStorage.setItem('menus', JSON.stringify(nuevo_array_menus));
  };

  return (
    <>
      {listadoState != null && listadoState.length > 0 ? (
        listadoState.map(menu => {
          return (
            <article key={menu.id} className="menu-item">
              <h3 className="title">{menu.titulo}</h3>
              <p className="description">{menu.descripcion}</p>
              <button className="edit" onClick={() => setEditar(menu.id)}>
                Editar
              </button>
              <button className="delete" onClick={() => borrarMenu(menu.id)}>
                Borrar
              </button>
              {editar === menu.id && (
                <Editar
                  menu={menu}
                  conseguirMenus={conseguirMenus}
                  setEditar={setEditar}
                  setListadoState={setListadoState}
                />
              )}
            </article>
          );
        })
      ) : (
        <div className="sin-menus">
          <h1>Aún no hay menús añadidos.</h1>
          <h3>Añade para probar, se almacenarán en tu localStorage</h3>
        </div>
      )}
    </>
  );
};
