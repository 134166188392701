import React, { useState } from "react";
import { Añadir } from "../components/Añadir";
import { Buscador } from "../components/Buscador";
import { Listado } from "../components/Listado";

const Inicio = () => {
  const [listadoState, setListadoState] = useState([]);

  return (
    <div className="layout">
      <section id="content" className="content">
        
        <Listado listadoState={listadoState} setListadoState={setListadoState} />
      </section>
      <aside className="lateral">
        <Buscador listadoState={listadoState} setListadoState={setListadoState} />
        <Añadir setListadoState={setListadoState} />
      </aside>
    </div>
  );
};

export default Inicio;
